import React from 'react';
import Navbar from "../components/App/NavbarEn"
import Footer from "../components/App/FooterEn"
import Layout from "../components/App/Layout"
import MainBanner from '../components/En/MainBanner'
import Funfacts from '../components/En/Funfacts'
import Services from '../components/En/Services'

const En = () => {
    return (
        <Layout>
            <Navbar />
            <MainBanner />
            <Funfacts />
            <Services />
            <Footer />
        </Layout>
    );
}

export default En;